import "../imgbox/ImageBox.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

function ImageBoxes() {
  const data = [
    {
      id: 1,
      img: "Naroshankar.JPG",
      title: "Naroshankar Ghanta",
      desc: "An important heritage site along the Godavari river.",
    },
    {
      id: 2,
      img: "kalaram.jpg",
      title: "Kalaram Mandir",
      desc: "Known for Kumbh Mela, a key focus in the development plan.",
    },
    {
      id: 3,
      img: "Mamledar.jpeg",
      title: "Dev Mamledar Tower ",
      desc: " A key area for riverfront development showcasing Nashik's rich history.",
    },
    {
      id: 4,
      img: "holkar.jpeg",
      title: "  Punyashlok Ahilyabai Holkar Bridge",
      desc: "An opportunity to enhance cultural heritage through design.",
    },
    {
      id: 5,
      img: "narayn.jpg",
      title: "Sundar Narayan Mandir",
      desc: "An opportunity to enhance cultural heritage through design",
    },
    {
      id: 6,
      img: "gadge.jpg",
      title: "Sant Gadage Maharaj Dharamshala",
      desc: "An opportunity to enhance cultural heritage through design.",
    },
  ];
  return (
    <>
      {/* <div className="container text-center py-5">
        <h1 className="primary-title">आपली गोदावरी</h1>
        <h2 className="secondary-title" style={{ textTransform: "uppercase" }}>
          Our Godavari River Front Development Design Competition
        </h2>
        <h4>ICONIC LANDMARKS ALONG THE RIVERFRONT</h4>
        <div className="row mt-5">
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card card-hover h-100 shadow-lg border-0">
              <img
                src="Naroshankar.JPG"
                className="card-img-top"
                alt="Panchavati Ghat"
              />
              <div className="card-body">
                <h5 className="card-title">Naroshankar Ghanta</h5>
                <p className="card-text">
                  An important heritage site along the Godavari river.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card card-hover h-100 shadow-lg border-0">
              <img src="kalaram.jpg" className="card-img-top" alt="Ramkund" />
              <div className="card-body">
                <h5 className="card-title">Kalaram Mandir</h5>
                <p className="card-text">
                  Known for Kumbh Mela, a key focus in the development plan.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card card-hover h-100 shadow-lg border-0">
              <img
                src="Mamledar.jpeg"
                className="card-img-top"
                alt="Nashik River View"
              />
              <div className="card-body">
                <h5 className="card-title">Dev Mamledar Tower </h5>
                <p className="card-text">
                  A key area for riverfront development showcasing Nashik's rich
                  history.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card card-hover h-100 shadow-lg border-0">
              <img
                src="holkar.jpeg"
                className="card-img-top"
                alt="Cultural Heritage"
              />
              <div className="card-body">
                <h5 className="card-title">
                  Punyashlok Ahilyabai Holkar Bridge
                </h5>
                <p className="card-text">
                  An opportunity to enhance cultural heritage through design.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card card-hover h-100 shadow-lg border-0">
              <img
                src="narayn.jpg"
                className="card-img-top"
                alt="Cultural Heritage"
              />
              <div className="card-body">
                <h5 className="card-title">Sundar Narayan Mandir </h5>
                <p className="card-text">
                  An opportunity to enhance cultural heritage through design.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card card-hover h-100 shadow-lg border-0">
              <img
                src="/gadge.jpg"
                className="card-img-top"
                alt="Cultural Heritage"
              />
              <div className="card-body">
                <h5 className="card-title">Sant Gadage Maharaj Dharamshala </h5>
                <p className="card-text">
                  An opportunity to enhance cultural heritage through design.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* products */}
      <div className="container">
        <div className="">
          <h2
            className="secondary-title"
            style={{ textTransform: "uppercase" }}
          >
            ICONIC LANDMARKS ALONG THE RIVERFRONT
          </h2>
          <div className="row">
            <div className="col ">
              <Swiper
                modules={[Navigation, A11y]}
                spaceBetween={10}
                slidesPerView={1}
                navigation
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                  },
                }}
              >
                {data.map((item) => {
                  return (
                    <>
                      <SwiperSlide>
                        <div
                          className="rounded position-relative"
                          // style={{ height: "30rem" }}
                        >
                          <div className="row">
                            <div className="col mb-1">
                              <div
                                className="card text-center card-hover border-1 h-100"
                                // style={{ height: "18rem" }}
                              >
                                <img
                                  src={item.img}
                                  className="card-img-top"
                                  alt="Ramkund"
                                />
                                <div className="card-body">
                                  <h5 className="card-title">{item.title}</h5>
                                  {/* <p className="card-text">
                                    Known for Kumbh Mela, a key focus in the
                                    development plan.
                                  </p> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    </>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImageBoxes;
