import React from 'react'

import { ToastContainer } from "react-toastify";
import Home from "./pages/home/Home"
import { Route, Routes } from 'react-router-dom';
import TermsAndConditions from './pages/TermsAndConditions';
import Contact from './pages/contact/Contact';
import Navbar from './components/navbar/Navbar';
import DownLoad from './pages/Download';
import About from "./pages/About/About";
import Footer from './components/footer/Footer';
import Form from './components/form/Form';

const App = () => {
  return (
    <div>
      <ToastContainer toastClassName="toast-homepage" />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/termandconditions" element={<TermsAndConditions />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/download" element={<DownLoad />} />
        <Route path="/about" element={<About />} />
        <Route path="/form" element={<Form />} />
      </Routes>
      {/* <Footer /> */}
    </div>
  );
}

export default App
