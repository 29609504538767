import React from "react";

const Footer = () => {
  return (
    <div>
      <div className="container text-center">
        <h4>Organized By</h4>
        <h4 className="mb-3">
          Nashik Municipal Corporation and Trimbak Municipal Council
        </h4>
        <div className="row text-center">
          <div className="col-md-2 py-2">
            <img src="/nmc.png" className="img-fluid my-2" width={250} alt="" />
          </div>
          <div className="col-md-2 py-2">
            <img
              src="/trimbak.jfif"
              className="img-fluid my-2"
              width={70}
              alt=""
            />
          </div>
          <div className="col-md-2 py-2">
            <img
              src="/Life.png"
              className="img-fluid my-2"
              width={150}
              alt=""
            />
          </div>
          <div className="col-md-2 py-2">
            <img src="/75.jpg" className="img-fluid my-2" width={140} alt="" />
          </div>
          <div className="col-md-2 py-2">
            <img
              src="/swachbharat.png"
              className="img-fluid my-2"
              width={180}
              alt=""
            />
          </div>
          <div className="col-md-2 py-2">
            <img src="/swach.png" className="img-fluid" width={180} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
