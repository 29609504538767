import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "@mui/material";
import GroupForm from "./GropForm";
import IndividualForm from "./IndividualForm";
// import './form.css';

const Form = () => {
  const [formType, setFormType] = useState("individual");

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        // minHeight: "100vh",
        backgroundImage: "url('/2019011864-1024x683.jpg')", // Replace with your image
        backgroundSize: "cover",
        // backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        padding: "40px",
        // position: "relative",
        overflow: "hidden",
      }}
    >
      {/* Add a pseudo-element for the background image */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.5)", // Semi-transparent white overlay
          zIndex: -1,
        }}
      ></div>

      <Col
        md={8}
        className="shadow-lg p-5 rounded bg-white"
        style={{ backdropFilter: "blur(10px)" }}
      >
        <h2 className="secondary-title" style={{ textTransform: "uppercase" }}>
          Submit Your Proposal
        </h2>

        {/* Form Switcher */}
        <div className="d-flex justify-content-center mb-4">
          <Button
            variant={formType === "individual" ? "contained" : "outlined"}
            onClick={() => setFormType("individual")}
            className="mx-2"
            sx={{
              backgroundColor: formType === "individual" ? "#e18d20" : "white",
              color: formType === "individual" ? "white" : "#e18d20",
              borderRadius: "30px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "10px 20px",
              border: formType === "individual" ? "none" : "2px solid #e18d20",
            }}
          >
            Individual Entry Form
          </Button>
          <Button
            variant={formType === "group" ? "contained" : "outlined"}
            onClick={() => setFormType("group")}
            className="mx-2"
            sx={{
              backgroundColor: formType === "group" ? "#e18d20" : "white",
              color: formType === "group" ? "white" : "#e18d20",
              borderRadius: "30px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "10px 20px",
              border: formType === "group" ? "none" : "2px solid #e18d20",
            }}
          >
            Group Entry Form
          </Button>
        </div>

        {/* Conditional Form Rendering */}
        <div className="mt-4">
          {formType === "individual" ? <IndividualForm /> : <GroupForm />}
        </div>
      </Col>
    </div>
  );
};

export default Form;
