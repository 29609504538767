import React from "react";
import { NavLink } from "react-router-dom";

const HomeAbout = () => {
  return (
    <>
      <div className="container p-2 mt-5">
        {/* <div className="row">
          <div className="col-md-6"> */}
        {/* <iframe
          width="640"
          height="300"
          className="ratio ratio-1x1"
          src="https://www.youtube.com/embed/s5tc4iHw2lk"
          title="Moneylife Foundation 6th Anniversary: Dr. Praveen Gedam"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe> */}
        {/* </div> */}
        <div className="col-md-12">
          {/* <h1 style={{ color: "#068f94" }}>आपली गोदावरी</h1> */}
          {/* <h4>Riverfront Development Design Competition</h4> */}
          <p style={{ textAlign: "justify" }} className="fs-5">
            We are thrilled to invite everyone to participate in the competition
            to reimagine and transform the Godavari riverfront from
            Trimbakeshwar to Nashik. Competition presents an opportunity for
            creative minds to ideate on a sustainable, feasible, and innovative
            urban solution to restore and enhance the beauty and cultural
            significance of Godavari. We encourage participants to provide
            special attention to envision.
          </p>
          <NavLink to="/about" className="btn text-white">
            {" "}
            Read More
          </NavLink>
          {/* </div>*/}
        </div>
      </div>
    </>
  );
};

export default HomeAbout;
