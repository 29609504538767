import React, { useState } from "react";
import "./navabar.css"
import { NavLink } from "react-router-dom";
import { IoIosMailUnread } from "react-icons/io";
import { FaPhoneSquareAlt } from "react-icons/fa";

const Navbar = () => {
  return (
    <>
      <div className="row my-1 gx-0">
        <div className="col-md-2 text-center mt-2">
          <a className="navbar-brand" href="#">
            <img src="/logo2.png" alt="Logo" />
          </a>
        </div>
        <div className="col-md-7 text-center align-self-center text-primary">
          <h3 className="name">NASHIK MUNICIPAL CORPORATION, NASHIK</h3>
          <h5 className="fs-4">Godavari Conservation Department</h5>
        </div>
        <div className="col-md-3 text-center mt-1">
          <div className="social-media">
            <ul>
              <li>
                <a href="https://www.facebook.com/mynashikmc/">
                  <i className="fab fa-facebook-f text-white"></i>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/my_nmc/">
                  <i className="fab fa-instagram text-white"></i>
                </a>
              </li>
              <li>
                <a href="https://x.com/my_nmc">
                  <i className="fab fa-twitter text-white"></i>
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/c/mynmc">
                  <i className="fab fa-youtube text-white"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="contact-info">
            <div className="contact-item">
              <a href="mailto:godavarinmc@gmail.com">
                <IoIosMailUnread /> godavarinmc@gmail.com
              </a>
            </div>
            <div className="contact-item">
              <a href="tel:02532304220">
                {" "}
                <FaPhoneSquareAlt /> +91-7030300300
              </a>
            </div>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink className="nav-link" to="/">
                  HOME
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/about">
                  ABOUT COMPETITION
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/download">
                  DOWNLOAD
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/contact">
                  CONTACT US
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
