import { useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { TextField, Button, FormControl, InputAdornment } from "@mui/material";
import { NavLink } from "react-router-dom";
import * as Yup from "yup";
import Swal from "sweetalert2";
import axios from "axios";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import withReactContent from "sweetalert2-react-content";
import GroupForm from "./GropForm";
// import './form.css';
const swalalert = withReactContent(Swal);

const initialValues = {
  fullname: "",
  email: "",
  mobile: "",
  occupation: "",
  // state: "",
  // nationality: "",
  short_idea: "",
  upload_proposal: null,
};

const IndividualForm = ({ formRef }) => {
  const [formType, setFormType] = useState("individual");

  const {
    values,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
    errors,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      fullname: Yup.string()
        .min(2, "Enter a valid Firstname")
        .max(25)
        .required("Please Enter Your Full Name"),
      email: Yup.string()
        .email("Enter a valid email")
        .required("Please Enter Your Email"),
      mobile: Yup.string()
        .min(10, "Enter valid Contact Number")
        .max(10, "Enter valid Contact Number")
        .required("Please Enter Your Contact Number"),
      occupation: Yup.string()
        .min(2, "Enter a valid occupation")
        .max(60)
        .required("Please Enter Your occupation"),
      // state: Yup.string()
      //   .min(2, "Enter a valid state")
      //   .max(60)
      //   .required("Please Enter Your state"),
      // nationality: Yup.string()
      //   .min(2, "Enter valid nationality")
      //   .max(60)
      //   .required("Please Enter Your nationality"),
      short_idea: Yup.string()
        .min(2, "Enter valid short idea")
        .max(25)
        .required("Please Enter Your short idea"),
      upload_proposal: Yup.mixed()
        .required("Please Upload Proposal")
        .test("fileSize", "File size too large", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
    }),
    onSubmit: (values, action) => {
      // Validate images manually before submission
      if (!values.upload_proposal) {
        toast.error("Please select an Aadhar Card image");
        return;
      }

      // Create FormData and append values
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      swalalert
        .fire({
          title: "Are You Sure To Submit the Application?",
          text: `Confirm?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          buttonsStyling: true,
        })
        .then((swalObject) => {
          if (swalObject.isConfirmed) {
            axios
              .post(`/individual/addindividual`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  //   Authorization: user.authToken,
                },
              })
              .then(function (response) {
                if (response.status === 200 || response.status === 201) {
                  swalalert.fire({
                    title: "Success!",
                    text: `Application Submitted Successfully Your Application Id Is ${response.data.id}`,
                    icon: "success",
                  });
                  resetForm();
                }
              })
              .catch((error) => {
                toast.error(
                  error.response?.data?.message ||
                    error.response?.data?.error ||
                    "Failed to connect to server"
                );
              });
          }
        });
    },
  });

  return (
    <div>
      <div>
        <Col md={12}>
          {/* Conditional Form Rendering */}

          <form onSubmit={handleSubmit}>
            <Row>
              <Col md={12}>
                <TextField
                  name="fullname"
                  margin="dense"
                  type="text"
                  placeholder="Applicant Full Name"
                  variant="outlined"
                  label="Applicant Full Name"
                  value={values.fullname}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                {errors.fullname ? (
                  <p className="text-danger mb-0">{errors.fullname}</p>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <TextField
                  name="email"
                  margin="dense"
                  type="email"
                  placeholder="Applicant Email Id"
                  variant="outlined"
                  label="Applicant Email Id"
                  value={values.email}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                {errors.email ? (
                  <p className="text-danger mb-0">{errors.email}</p>
                ) : null}
              </Col>
              <Col md={12}>
                <TextField
                  name="mobile"
                  margin="dense"
                  type="number"
                  placeholder="Contact Number"
                  variant="outlined"
                  label="Applicant Contact Number"
                  value={values.mobile}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                {errors.mobile ? (
                  <p className="text-danger mb-0">{errors.mobile}</p>
                ) : null}
              </Col>
            </Row>
            <Col md={12}>
              <FormControl fullWidth required className="mt-2">
                <TextField
                  name="occupation"
                  margin="dense"
                  type="text"
                  placeholder="Enter Occupation"
                  variant="outlined"
                  label=" Occupation"
                  value={values.occupation}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                {errors.occupation ? (
                  <p className="text-danger mb-0">{errors.occupation}</p>
                ) : null}
              </FormControl>
            </Col>
            {/* <Col md={12}>
              <FormControl fullWidth required className="mt-2">
                <TextField
                  name="state"
                  margin="dense"
                  type="text"
                  placeholder="Enter State"
                  variant="outlined"
                  label=" State"
                  value={values.state}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                {errors.state ? (
                  <p className="text-danger mb-0">{errors.state}</p>
                ) : null}
              </FormControl>
            </Col>
            <Col md={12}>
              <FormControl fullWidth required className="mt-2">
                <TextField
                  name="nationality"
                  margin="dense"
                  type="text"
                  placeholder="Enter Nationality"
                  variant="outlined"
                  label=" Nationality"
                  value={values.nationality}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                {errors.nationality ? (
                  <p className="text-danger mb-0">{errors.nationality}</p>
                ) : null}
              </FormControl>
            </Col> */}
            <Row>
              <Col md={12}>
                <FormControl fullWidth required className="">
                  <TextField
                    name="short_idea"
                    margin="dense"
                    type="text"
                    placeholder="Enter Description"
                    variant="outlined"
                    label="Short Description (500 words)"
                    value={values.short_idea}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                  {errors.short_idea ? (
                    <p className="text-danger mb-0">{errors.short_idea}</p>
                  ) : null}
                </FormControl>
              </Col>

              <Col md={12}>
                <TextField
                  name="upload_proposal"
                  margin="dense"
                  type="file"
                  variant="outlined"
                  label="Upload (jpg/png/pdf/PPT or Any File Type ) *100MB"
                  className="mt-4"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className="mt-5">
                        <label htmlFor="image"></label>
                      </InputAdornment>
                    ),
                    inputProps: {
                      onChange: (event) => {
                        setFieldValue(
                          "upload_proposal",
                          event.currentTarget.files[0]
                        );
                      },
                    },
                  }}
                />
                {errors.area ? (
                  <p className="text-danger mb-0">{errors.area}</p>
                ) : null}
              </Col>
            </Row>

            <div className="text-start mb-2">
              <input
                className="me-2 mt-3"
                name=""
                type="checkbox"
                accept=".pdf"
                required
              />
              <NavLink
                to="/termandconditions"
                className="text-decoration-none"
                style={{ color: "#e18d20" }}
              >
                I Agree To All the Terms and Conditions
              </NavLink>
            </div>

            <Button
              variant="contained"
              type="submit"
              className="px-3 text-center my-1 btn btn-info"
            >
              Submit
            </Button>
          </form>
        </Col>
      </div>
    </div>
  );
};

export default IndividualForm;
