import React from "react";
import { Container, Card, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const TermsAndConditions = () => {
  return (
    <Container className="mt-5">
      <Card className="p-4 terms-card">
        <h2 className="text-center mb-4">Terms and Conditions</h2>
        <div className="terms-content">
          <p>
            1. The design must be original. Plagiarism of any nature will not be
            allowed, design should not be in violation of copyright Acts in the
            country of origin or the Indian Copyright Act, 1957 or the
            Intellectual Property Rights of any third party.
          </p>
          <p>
            2. By agreeing to submit, an applicant hereby: warrants that he or
            she is the original designer/creator of the design. Further, that
            the design submitted by the applicant or any constituent part of it
            is not the intellectual property of any third party. The applicant
            also understands that in case the submitted design is found to be
            the intellectual property of any third party, his/her application
            will be stand rejected, and the Nashik Municipal Corporation (NMC)
            will not be responsible for any infringement whatsoever. Also, NMC
            will not indemnify any claim by a third party in connection with the
            infringement of Intellectual Rights related to design submitted by
            an applicant.
          </p>
          <p>
            3. In case of any copyright issues, the applicant will be
            responsible to settle any legal proceedings arising out of it at
            his/her end. NMC will not be responsible.
          </p>
          <p>
            4. All entries are governed by the provisions of Emblems and Names
            (Prevention of improper use) Act, 1950 and any violation of the said
            Act will result in disqualification.
          </p>
          <p>
            5. The design must not contain any provocative, objectionable, or
            inappropriate content.
          </p>
          <p>
            6. All winners shall be required to provide the original open-source
            file of the design. The winning designs would be the intellectual
            property of the NMC, and the winner shall not exercise any right
            over it.
          </p>
          <p>
            7. NMC will have unfettered right to modify the prize-winning design
            / entry or add/delete any info/ design feature in any form to it.
            The winner will not exercise any right over their design and shall
            not use it in any way.
          </p>
          <p>
            8. The winning design is meant to be used by NMC for promotional and
            display purposes, information, education, and communication
            materials and for any other use as may be deemed appropriate.
          </p>
          <p>
            9. There will be no notification to applicants of rejected entries.
          </p>
          <p>
            10. NMC reserves the right to cancel or amend all or any part of
            this competition and/ or terms and conditions/ technical parameters/
            evaluation criteria. However, any changes to the terms and
            conditions/ technical parameters/ evaluation criteria, or the
            cancellation of the competition, will be updated/ posted on the
            platform through which initial communication of competition is made.
            It would be the responsibility of the participant to keep themselves
            informed of any changes in the terms and conditions/technical
            parameters / evaluation criteria stated for this competition.
          </p>
          <p>
            11. The responsibility to comply with the submission of entries,
            competition technical criteria and selection process fully lies with
            the participant(s) and NMC shall not be answerable to any dispute
            raised by a third party.
          </p>
          <p>
            12. The winner will be declared through email. NMC may announce the
            winner’s name on its social media pages and may also upload details
            on the official website of the corporation.
          </p>
          <p>
            13. NMC reserves rights to reject any proposal. A proposal will be
            excluded from participation if:
            <ul>
              <li>If it is not sent within the deadline</li>
              <li>If documentation does not comply with the requirements</li>
              <li>
                This is an anonymous competition, and the unique identity code
                is the only means of identification. The name of participants
                should neither appear on the submission nor as part of the file
                names
              </li>
              <li>
                Entry is open to all participants except jury, organizing
                committee and their family members
              </li>
              <li>
                Participants who try to contact members of the jury will be
                disqualified
              </li>
            </ul>
          </p>
          <p>14. An individual can only be part of a single team/proposal.</p>
          <p>
            15. Proposals can be submitted individually or in a team comprising
            a maximum of 5 members.
          </p>
        </div>
        {/* <div className="text-center mt-4">
          <NavLink to="/selectgroup">
            <Button variant="primary">Proceed</Button>
          </NavLink>
        </div> */}
      </Card>
    </Container>
  );
};

export default TermsAndConditions;
