import { Col, Container, Form, Row } from "react-bootstrap";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Modal,
  Box,
  IconButton,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { InputAdornment } from "@mui/material";
import { useState } from "react";
import { Delete as DeleteIcon } from "@mui/icons-material"; // Import Delete icon
import { AiFillPlusCircle } from "react-icons/ai";
// import "./groupform.css";

const initialValues = {
  fname: "",
  lname: "",
  email: "",
  contactno: "",
  address: "",
  area: "",
  division: "",
  wardno: "",
  organization_name: "",
  organization_president: "",
  organization_address: "",
  reason: "",
};

const GroupForm = () => {
  document.title = "Group Compedition";
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [teamMembers, setTeamMembers] = useState([{ name: "", email: "" }]);

  // Handle input change for team member fields
  const handleTeamMemberChange = (index, event) => {
    const { name, value } = event.target;
    const newTeamMembers = [...teamMembers];
    newTeamMembers[index][name] = value;
    setTeamMembers(newTeamMembers);
  };

  // Add a new team member
  const addTeamMember = () => {
    const lastMember = teamMembers[teamMembers.length - 1];
    if (!lastMember.name || !lastMember.email) {
      toast.error(
        "Please fill the current team member's details before adding another."
      );
      return;
    }
    setTeamMembers([...teamMembers, { name: "", email: "" }]);
  };

  // Remove a team member by index
  const removeTeamMember = (index) => {
    const newTeamMembers = teamMembers.filter((_, i) => i !== index);
    setTeamMembers(newTeamMembers);
  };

  const {
    values,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
    errors,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      fname: Yup.string()
        .min(2, "Enter a valid Firstname")
        .max(25)
        .required("Please Enter Your First Name"),
      lname: Yup.string()
        .min(2, "Enter valid Lastname")
        .max(25)
        .required("Please Enter Your Last Name"),
      email: Yup.string()
        .email("Enter a valid email")
        .required("Please Enter Your Email"),
      wardno: Yup.number()
        .min(1, "Enter a valid Ward Number")
        .max(31, "Invalid Ward Number")
        .required("Please Enter Ward Number"),
      address: Yup.string()
        .min(2, "Enter a valid address")
        .max(60)
        .required("Please Enter Your Address"),
      area: Yup.string()
        .min(2, "Enter a valid area")
        .max(60)
        .required("Please Enter Your Area"),
      division: Yup.string()
        .min(2, "Enter valid division")
        .max(60)
        .required("Please Enter Description"),
      contactno: Yup.string()
        .min(2, "Enter valid Contact Number")
        .max(10)
        .required("Please Enter Your Contact Number"),
      organization_name: Yup.string()
        .min(2, "Enter valid Organisation Name")
        .max(25)
        .required("Please Enter Your Organisation Name"),
      organization_president: Yup.string()
        .min(2, "Enter valid President Name")
        .max(25)
        .required("Please Enter Your Organisation President Name"),
      organization_address: Yup.string()
        .min(2, "Enter valid Organisation Address")
        .max(60)
        .required("Please Enter Your Organisation Address"),
      reason: Yup.string()
        .min(10, "Please Enter the reason in Detail")
        .max(200, "Max Character Limit Reached")
        .required("Reason in required"),
    }),

    onSubmit: (values, action) => {
      console.log(values);
      axios
        .post("/volunteer/addvolunteer", values, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {
          console.log("api response", response.data);
          if (response.status === 200 || response.status === 201) {
            if (response.data) {
              toast.success("Message Send successfully");
              navigate("/");
              resetForm();
            }
          }
        })
        .catch(function (error) {
          if (error instanceof AxiosError && error.response?.data?.message)
            toast.error(error.response.data.message);
          else if (error.response?.data?.error) {
            toast.error(error.response.data.error);
          } else {
            toast.error("Failed to connect to server");
            console.log(error.message);
          }
        });
    },
  });

  return (
    <>
      {/* <div className="row mt-3">
        <div className="row mt-3">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <h3 className="text-center fw-bold text-uppercase">
              Mazhi Godavari Riverfront development Competition
            </h3>
            <hr />
          </div>
          <div className="col-md-4"></div>
        </div>
      </div> */}
      <div>
        <Row>
          <Col md={12} className="">
            <form onSubmit={handleSubmit} className="">
              <Row>
                <Col md={12}>
                  <TextField
                    name="tname"
                    margin="dense"
                    type="text"
                    placeholder="Enter Team Leaders Name"
                    variant="outlined"
                    label="Team Leaders Name"
                    value={values.fname}
                    onChange={handleChange}
                    fullWidth
                    required
                  ></TextField>
                  {errors.fname ? (
                    <p className="text-danger mb-0">{errors.fname}</p>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    name="nomemembers"
                    margin="dense"
                    type="text"
                    placeholder="Enter Number Of Members"
                    variant="outlined"
                    label="Number Of Team Members"
                    value={values.email}
                    onChange={handleChange}
                    fullWidth
                    required
                  ></TextField>
                  {errors.email ? (
                    <p className="text-danger mb-0">{errors.email}</p>
                  ) : null}
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <TextField
                    name="email"
                    margin="dense"
                    type="email"
                    placeholder="Team Leader Email Id"
                    variant="outlined"
                    label="Team Leader Email Id"
                    value={values.email}
                    onChange={handleChange}
                    fullWidth
                    required
                  ></TextField>
                  {errors.email ? (
                    <p className="text-danger mb-0">{errors.email}</p>
                  ) : null}
                </Col>
                <Col md={12}>
                  <TextField
                    name="contactno"
                    margin="dense"
                    type="number"
                    placeholder="9876543321"
                    variant="outlined"
                    label="Team Leader Contact Number"
                    value={values.contactno}
                    onChange={handleChange}
                    fullWidth
                    required
                  ></TextField>
                  {errors.contactno ? (
                    <p className="text-danger mb-0">{errors.contactno}</p>
                  ) : null}
                </Col>
              </Row>
              {/* Team Members Section */}
              <div className="mt-4">
                <h5>
                  Team Members{" "}
                  <button
                    onClick={addTeamMember}
                    className="btn btn-primary btn-sm mt-1"
                  >
                    <AiFillPlusCircle />
                  </button>
                </h5>

                {teamMembers.map((member, index) => (
                  <Row key={index} className="mb-3 align-self-center">
                    <Col md={5}>
                      <TextField
                        name="name"
                        margin="dense"
                        type="text"
                        placeholder="Member Name"
                        label="Member Name"
                        value={member.name}
                        onChange={(e) => handleTeamMemberChange(index, e)}
                        fullWidth
                        required
                      />
                    </Col>
                    <Col md={5}>
                      <TextField
                        name="email"
                        margin="dense"
                        type="email"
                        placeholder="Member Email"
                        label="Member Email"
                        value={member.email}
                        onChange={(e) => handleTeamMemberChange(index, e)}
                        fullWidth
                        required
                      />
                    </Col>
                    <Col md={1}>
                      <button
                        onClick={() => removeTeamMember(index)}
                        className="btn btn-danger btn-sm mt-2"
                        disabled={teamMembers.length === 1} // Disable delete for the last member
                      >
                        <DeleteIcon />
                      </button>
                    </Col>
                    {/* Add Member Button */}
                  </Row>
                ))}
              </div>

              <Row>
                <Col md={12}>
                  <FormControl fullWidth required className="">
                    <TextField
                      name="description"
                      margin="dense"
                      type="text"
                      placeholder="Enter Description"
                      variant="outlined"
                      label="Short Description (min 500 words)"
                      value={values.division}
                      onChange={handleChange}
                      fullWidth
                      required
                    ></TextField>
                    {errors.division ? (
                      <p className="text-danger mb-0">{errors.division}</p>
                    ) : null}
                  </FormControl>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <TextField
                    name="image"
                    margin="dense"
                    type="file"
                    variant="outlined"
                    className="mt-4"
                    label="Upload (jpg/png/pdf/PPT or Any File Type ) *100MB"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <label htmlFor="image"></label>
                        </InputAdornment>
                      ),
                      inputProps: {
                        accept: ".jpg, .png, .jpeg",
                        onChange: (event) => {
                          setFieldValue("image", event.currentTarget.files[0]);
                        },
                      },
                    }}
                  />

                  {errors.area ? (
                    <p className="text-danger mb-0">{errors.area}</p>
                  ) : null}
                </Col>
              </Row>

              <div className="text-start mb-2">
                <input
                  className="me-2 mt-3"
                  name="image"
                  type="checkbox"
                  accept=".pdf"
                  required
                />
                <NavLink
                  to="/termandconditions"
                  className="text-decoration-none"
                  style={{ color: "#e18d20" }}
                >
                  I Agree To All the Terms and Conditions
                </NavLink>
              </div>
              <Button
                variant="contained"
                type="submit"
                className="px-3 text-center my-1 btn btn-info"
              >
                Submit
              </Button>

              {/* Button to open modal */}
              {/* <Button
              variant="contained"
              onClick={handleOpenModal}
              className="mt-3"
            >
              Add Team Members
            </Button> */}

              {/* Modal for adding team members */}
              {/* <Modal
              open={openModal}
              onClose={handleCloseModal}
              aria-labelledby="add-team-member-modal"
              aria-describedby="add-team-member-form"
              size="lg"
            >
              <Box
                sx={{
                  p: 4,
                  backgroundColor: "white",
                  maxWidth: 500,
                  margin: "auto",
                }}
              >
                <h4>Add Team Members</h4>

                {teamMembers.map((member, index) => (
                  <div key={index} className="team-member-form">
                    <Row>
                      <div className="col-md-5">
                        <TextField
                          name="name"
                          margin="dense"
                          type="text"
                          placeholder="Enter Member Name"
                          variant="outlined"
                          label={`Member Name ${index + 1}`}
                          value={member.name}
                          onChange={(e) => handleTeamMemberChange(index, e)}
                          fullWidth
                          required
                        />
                      </div>

                      <div className="col-md-5">
                        <TextField
                          name="email"
                          margin="dense"
                          type="email"
                          placeholder="Enter Member Email"
                          variant="outlined"
                          label={`Member Email ${index + 1}`}
                          value={member.email}
                          onChange={(e) => handleTeamMemberChange(index, e)}
                          fullWidth
                          required
                        />
                      </div>
                      <div className="col-md-2 mt-2">
                        <IconButton
                          onClick={() => removeTeamMember(index)}
                          aria-label="delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </Row>
                  </div>
                ))}

                <Button
                  variant="outlined"
                  onClick={addTeamMember}
                  className="mt-3"
                >
                  Add Another Member
                </Button>
                <Button
                  variant="contained"
                  onClick={handleCloseModal}
                  className="mt-3 ml-2"
                >
                  Close
                </Button>
              </Box>
            </Modal> */}
            </form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default GroupForm;
