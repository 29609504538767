import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { HiOutlineLightBulb } from "react-icons/hi";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { GiNotebook } from "react-icons/gi";
import { AiTwotoneEye } from "react-icons/ai";
import { PiPlantFill } from "react-icons/pi";
import { FaHandsHoldingChild } from "react-icons/fa6";
import { FaRecycle } from "react-icons/fa";
import "./about.css";
import { FaMoneyBillTrendUp } from "react-icons/fa6";

const About = () => {
  return (
    <div className="shadow m-3">
      <Container>
        <div className="main  my-3 rounded-2 ">
          <h2 className="col1">CULTURAL CONTEXT</h2>

          <p style={{ textAlign: "justify" }}>
            Nashik’s distinct personality is deeply rooted in its rich
            mythological, historical, social, and cultural significance. As one
            of the holiest cities in India, it sits on the banks of the sacred
            Godavari river, revered as the “Dakshin Ganga” or Ganges of the
            South. Its spiritual legacy is intertwined with the epic Ramayana.
            Over the centuries, Nashik has grown into a major pilgrimage hub,
            falling on the circuit to Trimbakeshwar, Shirdi, Shani-Shinganapur,
            Vani, Chandwad etc. drawing devotees from all over India for its
            numerous temples, ghats, and sacred rituals. The cultural landscape
            of Nashik is also shaped by the Kumbh Mela, one of the largest
            religious gatherings in the world, which occurs every 12 years. The
            city, along with nearby Trimbakeshwar, becomes a magnet for millions
            of pilgrims who come to take a holy dip in the Godavari, believed to
            cleanse sins and offer spiritual liberation. This festival, marked
            by colorful processions, religious discourses, and rituals, further
            amplifies Nashik and Trimbakeshwar’s cultural vibrancy and
            highlights their importance as spiritual centres.
            <p>
              Refer
              <b>
                <a href="https://nashik.gov.in/about-district/history/#:~:text=The%20city%20got%20its">
                  [Nashik District, Government of Maharashtra]
                </a>
              </b>
              for more information
            </p>
          </p>
          <div className="text-center border border-1 border-danger rounded-4">
            <img src="/BrosureBackground.JPG" alt="" className="img-fluid " />
          </div>
        </div>

        <div className="main  my-1 rounded-2 p-2">
          <h2 className="col1"> NASHIK</h2>
          <p style={{ textAlign: "justify" }}>
            Nashik harmoniously blends cultural heritage with industrial growth.
            It plays a significant role in Hindu culture, being a revered site
            for the Kumbh Mela, one of the largest religious gatherings in the
            world. The city is adorned with ancient temples and is also known
            for its traditional arts and crafts. Nashik has emerged as a
            prominent economic hub in the region surrounded by lush hills and
            scenic landscapes. The favourable climate, strategic location and
            excellent connectivity by road, rail and air makes it an ideal
            investment destination for residential, commercial and industrial
            purposes. It boasts a diverse industrial base, including
            manufacturing, agriculture, and notably, the wine industry often
            referred to as the “Wine Capital of India”. Based on 2011 census
            figures, the population density of Nashik Municipal Corporation is
            5556 persons per sq. km. and is divided into 61 wards. Population as
            per census 2011 was 14.85 lakh and is estimated to be approximately
            22.50 lakhs in 2024.
            <p>
              Refer
              <b>
                <a href="https://nashik.gov.in/about-district/history/#:~:text=The%20city%20got%20its">
                  [Nashik District, Government of Maharashtra]
                </a>
              </b>
              for more information
            </p>
          </p>

          <h2 className="col1"> TRIMBAKESHWAR</h2>
          <p style={{ textAlign: "justify" }}>
            Trimbakeshwar is 28 km away from Nashik. Its economy is primarily
            driven by religious tourism, as it is home to one of the twelve
            Jyotirlingas, attracting thousands of pilgrims annually. The
            hospitality industry, including hotels, lodges, and restaurants,
            plays a vital role, while agriculture, particularly the cultivation
            of rice, millet, and fruits, also contributes to the local economy.
            Small-scale industries like handicrafts and the production of
            religious artifacts cater to the pilgrim market. Population of
            Trimbakeshwar as per census 2011 is 12,056. It is estimated to be
            16,800 in 2024. Trimbak Municipal Council encompasses an area of
            13.63 sq. km. The town experiences fluctuations in density due to
            seasonal pilgrim influxes. The local population, predominantly rural
            and engaged in agriculture or tourism-related services, faces
            challenges like limited employment opportunities and infrastructure
            strain during peak seasons.
            <p>
              Refer
              <b>
                <a href="https://nashik.gov.in/about-district/history/#:~:text=The%20city%20got%20its">
                  [Nashik District, Government of Maharashtra]
                </a>
              </b>
              for more information
            </p>
          </p>
          {/* <div className="text-center border border-1 border-danger rounded-4">
            <img src="/AboutNashik.JPG" alt="" className="img-fluid  " />
          </div> */}
        </div>

        <div className="container  main  my-1 rounded-2 p-2">
          <div className="row justify-content-center ">
            <div className="col-12">
              <h2 className="col1">CITY VISION</h2>
              <p className="" style={{ textAlign: "justify" }}>
                Nashik aspires to be clean, green, safe, economically developed
                and well-planned city having world class infrastructure well
                connected with major cities of India. As a historical city, it
                offers diverse cultural and lifestyle experiences to its
                citizens and visitors. Being a part of the Golden Triangle
                (Mumbai-Pune-Nashik), it offers diverse employment opportunities
                along with good infrastructure and a responsive local
                government. The physical heritage is manifested through the
                Godavari river and its beautiful ghats in the heart of the city
                which enables pleasant climatic conditions. Also, over 40% of
                city’s land is green cover which contributes to a cooler
                climate.
              </p>
              <strong>
                <p>The following goals are embedded within this vision:</p>
              </strong>
            </div>
          </div>
          <div className="text-center ">
            <div className="row text-center mt-5">
              <div className="col-md-4">
                <div className="icon-box mb-4">
                  <i className="fas fa-hiking fa-3x mb-3"></i>
                  <h5 className="ps-1">
                    Development Nashik as a tourism destination
                  </h5>
                </div>
              </div>
              <div className="col-md-4">
                <div className="icon-box mb-4">
                  <i className="fas fa-chart-line fa-3x mb-3"></i>
                  <h5>Market Nashik as investment destination</h5>
                </div>
              </div>
              <div className="col-md-4">
                <div className="icon-box mb-4">
                  <i className="fas fa-wine-glass-alt fa-3x mb-3"></i>
                  <h5>Promote wine tourism</h5>
                </div>
              </div>
            </div>

            <div className="row text-center mt-4">
              <div className="col-md-4">
                <div className="icon-box mb-4">
                  <i className="fas fa-car fa-3x mb-3"></i>
                  <h5 className="ps-2">
                    Adopt sustainable practices for traffic management
                  </h5>
                </div>
              </div>
              <div className="col-md-4">
                <div className="icon-box mb-4">
                  <i className="fas fa-city fa-3x mb-3"></i>
                  <h5>Development Nashik as a compact city</h5>
                </div>
              </div>
              <div className="col-md-4">
                <div className="icon-box mb-4">
                  <i className="fas fa-balance-scale fa-3x mb-3"></i>
                  <h5>Develop open, accountable and responsible governance</h5>
                </div>
              </div>
            </div>
          </div>
          <p>
            Refer
            <b>
              {" "}
              <a href="https://nashiksmartcity.in/aboutnashik#:~:text=Nashik%20city%20will%20be%20clean,to%20its%20citizens%20and%20visitors.">
                [Nashik Smart City Website]
              </a>
            </b>
            for more information
          </p>
        </div>

        <div className="row text-center  my-2 rounded-2 p-2">
          <div className="text-start">
            <h2 className="col1">आपली गोदावरी</h2>
            <strong>
              <h4>Riverfront Development Design Competition</h4>
            </strong>

            <p style={{ textAlign: "justify" }}>
              We are thrilled to invite everyone to participate in the
              competition to reimagine and transform the Godavari riverfront
              from Trimbakeshwar to Nashik. Competition presents an opportunity
              for creative minds to ideate on a sustainable, feasible, and
              innovative urban solution to restore and enhance the beauty and
              cultural significance of Godavari. We encourage participants to
              provide special attention to envision :
            </p>

            <ul>
              <li>
                (i) Kushavart Tirth-Ahilya Godavari Sangam-Shri Chandra Ghat
                stretch in Trimbakeshwar
              </li>
              <li>
                (ii) Punya Shlok Ahilya Devi Holkar bridge to Talkuteshwar
                temple stretch in Nashik
              </li>
            </ul>
            <p>
              as both these stretches are the most active, significant and
              sacred for the pilgrims and the citizens.
            </p>
            <h3>SUGGESTED THEMES</h3>
          </div>
          <div className="col-md-4">
            <div className="icon-box mb-4">
              <i className="fas fa-paint-brush fa-3x mb-3"></i>
              <h5>Aesthetic improvement</h5>
              <p>
                Enhancing the visual appeal of the area, including streetscapes,
                architecture, and public spaces.
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="icon-box mb-4">
              <i className="fas fa-landmark fa-3x mb-3"></i>
              <h5>Cultural integration</h5>
              <p>
                Proposals that honor the heritage of Nashik and its connection
                to the Godavari.
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="icon-box mb-4">
              <i className="fas fa-seedling fa-3x mb-3"></i>
              <h5>River rejuvenation</h5>
              <p>
                Local solutions restoring the ecological health of the Godavari,
                while reviving its natural beauty.
              </p>
            </div>
          </div>

          <div className="row text-center mt-4">
            <div className="col-md-4">
              <div className="icon-box mb-4">
                <i className="fas fa-globe fa-3x mb-3"></i>
                <h5>Development of public realm</h5>
                <p>
                  Creating spaces for community interactions, connectivity, and
                  leisure along the river.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="icon-box mb-4">
                <i className="fas fa-money-check-alt fa-3x mb-3"></i>
                <h5>Financial feasibility</h5>
                <p>
                  Ensuring that the project is financially sound with a return
                  on investment.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="icon-box mb-4">
                <i className="fas fa-recycle fa-3x mb-3"></i>
                <h5>Environmental sustainability</h5>
                <p>
                  Innovative solutions to improve water quality, prevent
                  pollution, and sustain the river’s ecosystem.
                </p>
              </div>
            </div>
          </div>

          <div className="row text-center mt-4">
            <div className="col-md-6">
              <div className="icon-box mb-4">
                <i className="fas fa-people-carry fa-3x mb-3"></i>
                <h5>formal & Informal markets</h5>
                <p>
                  Organising and shaping the temporary markets / vending areas
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="icon-box mb-4">
                <i className="fas fa-umbrella-beach fa-3x mb-3"></i>
                <h5>Pilgrim experience</h5>
                <p>
                  Improving accessibility, safety, and amenities for pilgrims
                  and visitors.
                </p>
              </div>
            </div>

            <div className="row text-center mt-4">
              <div className="col-md-6">
                <div className="icon-box mb-4">
                  {/* <i className="fas fa-people-carry fa-3x mb-3"></i> */}
                  <FaMoneyBillTrendUp className="fs-1 mb-3" />
                  <h5>Return on investment</h5>
                  <p>
                    Measuring economic, social, and environmental benefits
                    generated by the project
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="icon-box mb-4">
                  <FaMoneyBillTrendUp className="fs-1 mb-3" />
                  <h5>Recreational activities</h5>
                  <p>
                    Physical activity, leisure and entertainment activities that
                    have a positive impact on citizens well being
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-2  my-1 rounded-2">
          <h2 className="col1">SITE CONTEXT</h2>
          <p>
            The entire stretch of Godavari river from Kushavart tirth in
            Trimbakeshar to Dasak ghat area in Nashik holds immense cultural,
            environmental, and economic value, but faces challenges that require
            balanced development and conservation efforts.
          </p>

          <h5>Environment</h5>
          <ul style={{ textAlign: "justify" }}>
            <li>
              The Godavari river originates in Trimbakeshwar and flows through
              Nashik, creating a diverse ecological zone consisting of verdant
              hills, forests, and agricultural lands
            </li>
            <li>
              Biodiversity includes various aquatic species, birdlife, and
              native flora in the surrounding regions
            </li>
            <li>
              The river exhibits seasonal variation, with higher water levels
              during the monsoon and lower flow in summer. Flooding is a
              periodic issue during heavy monsoon seasons, especially in
              low-lying areas mainly in city of Nashik & Trimbakeshwar.
            </li>
          </ul>

          <h5>Significance</h5>
          <ul style={{ textAlign: "justify" }}>
            <li>
              The Godavari is often referred to as the “Dakshin Ganga” (Ganges
              of the South), holding immense cultural and religious
              significance.
            </li>
            <li>
              Trimbakeshwar is home to one of the 12 Jyotirlingas, making the
              river sacred to Hindus.
            </li>
            <li>
              The Panchavati area of Nashik is associated with the Ramayana,
              where Lord Rama, is believed to have resided, adding to its
              spiritual importance
            </li>
            <li>
              Nashik is one of the four cities that host the Kumbh Mela, a major
              Hindu pilgrimage event that draws millions of devotees for holy
              dips in the Godavari.{" "}
            </li>
          </ul>

          <h5>Activity Pattern</h5>
          <ul style={{ textAlign: "justify" }}>
            <li>
              The riverfront sees frequent religious, cultural, and social
              activities, especially in Nashik during the Kumbh Mela.{" "}
            </li>
            <li>
              Religious / Cultural Activities – Asthi Visarjan, Holy Snan, last
              rites, Mundan, Ganga Arati, Mandir Darshan etc.{" "}
            </li>
            <li>
              Recreational Activities – Boating, Swimming, Mela and Exhibitions,
              Pilgrimage tourism{" "}
            </li>
            <li>
              Cultural Activities - Vasant Vyakhyanmala, Satsang, Pravachan
              Programs, Processions
            </li>
            <li>
              Other Activities – Vegetable and grains market, Temporary Market,
              Traditional goods markets, Parking Areas, Night Shelters
            </li>
            <li>
              Agricultural practices along the banks are significant, with
              farmers depending on the river for irrigation.{" "}
            </li>
          </ul>

          <h5>Key Concerns</h5>
          <ul style={{ textAlign: "justify" }}>
            <li>
              Pollution and over-extraction of water pose environmental
              challenges, affecting aquatic life and water quality
            </li>
            <li>
              Waste disposal due to religious activities, Sewage disposal and
              industrial waste contribute to deteriorating water quality
            </li>
            <li>
              Unregulated construction, out of context building typologies, and
              encroachments by street vendors and small shop owners on the
              riverbanks impact the overall environment, and natural flow
            </li>
            <li>
              Over-extraction for agriculture and urban needs leads to decreased
              flow, especially in summer
            </li>
          </ul>
        </div>

        <div>
          <h2>SITE DETAILS</h2>
          <p>
            Competition invites innovative and practical design proposals for
            the development of following focus areas of Godavari riverfront from
            Trimbakeshwar to Nashik as depicted:
          </p>
          <h2>SITE LOCATION</h2>
          <div className="text-center border border-1 border-danger rounded-4 p-2">
            <img src="/site1.jpg" className="img-fluid" alt="" />
          </div>
          <div className="text-center my-3 border border-1 border-danger rounded-4 p-2">
            <img src="/site2.jpg" className="img-fluid" alt="" />
          </div>

          <h2>SITE DATA</h2>
          <p>
            Site specific data can downloaded from here <a href=""> Link</a>
          </p>
          <p>Data will be uploaded in this folder after competition launch</p>
        </div>

        <div className=" my-3  ">
          <div className="row justify-content-center">
            <div className="col-md-12">
              {/* Call to Participate Section */}
              <div className="bg-white rounded  mb-3">
                <h2
                  className="mb-4 col1"
                  style={{ textTransform: "uppercase" }}
                >
                  CALL TO PARTICIPATE
                </h2>
                <p style={{ fontSize: "1.1rem", lineHeight: "1.8" }}>
                  Submission phase of the competition will be active for a
                  duration of 3 months and will be announced on the website
                  &nbsp;
                  <b>
                    <a
                      href="https://godavari.nmc.gov.in/"
                      className="text-primary"
                    >
                      Visit
                    </a>
                  </b>{" "}
                  Participants are requested to follow related announcements on
                  the website.
                </p>
              </div>

              {/* Timeline Section */}
              <div className="bg-white p-2  mb-1">
                <h3 className=" mb-4">TIMELINE</h3>
                <table className="table table-bordered table-striped">
                  <thead className="thead-dark">
                    <tr>
                      <th>#</th>
                      <th>Stage</th>
                      <th>Due Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Launch of competition</td>
                      <td>XX Sep 2024</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Online sessions for queries and clarification</td>
                      <td>3 sessions</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Session 1</td>
                      <td>XX Oct 2024</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Session 2</td>
                      <td>XX Oct 2024</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Session 3</td>
                      <td>XX Nov 2024</td>
                    </tr>

                    <tr>
                      <td>3</td>
                      <td>Submission phase</td>
                      <td>XX Nov 2024</td>
                    </tr>
                  </tbody>
                </table>
                <p className="text-center">
                  Timelines, if modified, will be announced on the website.
                </p>
              </div>

              {/* Submission Guidelines Section */}
              <div className="bg-white p-2 rounded  mb-1">
                <h2
                  className=" mb-4 col1"
                  style={{ textTransform: "uppercase" }}
                >
                  Submission Guidelines
                </h2>
                <ul className="list-group " style={{ textAlign: "justify" }}>
                  <li className="list-group-item">
                    <strong>1.</strong> Any form of presentation, file size
                    (upto 100 MB) and format (PDF/JPG/PNG/PPT/DOC) is
                    acceptable. However we recommend the proposals to be
                    composed on landscape oriented A1 size sheets.
                  </li>
                  <li className="list-group-item">
                    <strong>2.</strong>. Any style of presentation and tool used
                    to convey the idea is acceptable – CAD drawings, GIS,
                    images, 3D model, pictures, sketches etc
                  </li>
                  <li className="list-group-item">
                    <strong>3.</strong> A 500-word proposal summary should also
                    be submitted.
                  </li>
                  <li className="list-group-item">
                    <strong>4.</strong> There is no entry fee for participation
                    in the competition.
                  </li>
                  <li className="list-group-item">
                    <strong>5.</strong> Stretch is divided into 6 focus areas
                    and participants can opt to submit solutions for one
                    section, part of one section, multiple sections or for the
                    entire stretch from Timbakeshwar to Nashik in a single
                    proposal.
                  </li>
                  <li className="list-group-item">
                    <strong>6.</strong>Entries would be judged based on elements
                    of creativity, technical excellence, visual impact and how
                    well they communicate the theme.
                  </li>
                </ul>
              </div>

              {/* Evaluation Criteria Section */}

              <div className="bg-white p-2 rounded mb-2">
                <h3
                  className="mb-4 col1"
                  style={{ textTransform: "uppercase" }}
                >
                  Evaluation Criteria
                </h3>
                <p style={{ textAlign: "justify" }}>
                  The proposals will be evaluated on the undermentioned
                  parameters by an evaluation committee. Details of jury and
                  further evaluation process will be informed to entries called
                  for presentation:
                </p>
                <div className="align-item-center text-center  ">
                  <img
                    src="/evalution.JPG"
                    alt=""
                    className="img-fluid rounded-4 "
                  />
                </div>
                {/* <div className="text-center">
                  <div className="row text-center mt-5">
                    <div className="col-md-4">
                      <div className="icon-box mb-4">
                        <i className="fas fa-3x mb-3">
                          <HiOutlineLightBulb />
                        </i>
                      </div>
                      <h5>Innovation</h5>
                    </div>
                    <div className="col-md-4">
                      <div className="icon-box mb-4">
                        <i className="fas fa-3x mb-3">
                          <FaMoneyCheckAlt />
                        </i>
                      </div>
                      <h5>Financial viability</h5>
                    </div>
                    <div className="col-md-4">
                      <div className="icon-box mb-4">
                        <i className="fas fa-3x mb-3">
                          <GiNotebook />
                        </i>
                      </div>
                      <h5>Implementation feasibility</h5>
                    </div>
                  </div>

                  <div className="row text-center mt-4">
                    <div className="col-md-4">
                      <div className="icon-box mb-4">
                        <i className="fas fa-3x mb-3">
                          <AiTwotoneEye />
                        </i>
                      </div>
                      <h5>Aesthetics</h5>
                    </div>
                    <div className="col-md-4">
                      <div className="icon-box mb-4">
                        <i className="fas fa-3x mb-3">
                          <PiPlantFill />
                        </i>
                      </div>
                      <h5>Environmental impact</h5>
                    </div>
                    <div className="col-md-4">
                      <div className="icon-box mb-4">
                        <i className="fas fa-3x mb-3">
                          <FaHandsHoldingChild />
                        </i>
                      </div>
                      <h5>Inclusive design</h5>
                    </div>
                    <div className="col-md-4">
                      <div className="icon-box mb-4">
                        <i className="fas fa-3x mb-3">
                          <FaRecycle />
                        </i>
                      </div>
                      <h5>Adaptivity to local conditions</h5>
                    </div>
                  </div>
                </div> */}
              </div>

              {/* Team Composition Section */}
              <div className="bg-white p-2 rounded ">
                <h4 className="col1" style={{ textTransform: "uppercase" }}>
                  Team Composition
                </h4>
                <ul className="list-group-item ">
                  <li className="">
                    Proposals can be submitted individually or in teams of up to
                    5 members.
                  </li>
                  <li className="">
                    No individual can be part of multiple proposals.
                  </li>
                </ul>
              </div>

              {/* Prizes Section */}
              <div className="bg-white p-2 rounded  mb-1 ">
                <h4 className="col1 " style={{ textTransform: "uppercase" }}>
                  Prizes{" "}
                  <span>
                    <small style={{ fontSize: "13px" }}>(in INR)</small>
                  </span>
                </h4>{" "}
                <table className="table table-bordered table-striped text-center">
                  <thead>
                    <tr>
                      <th>First</th>
                      <th>Second</th>
                      <th>Third</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>₹ 5,00,000</td>
                      <td>₹ 3,00,000</td>
                      <td>₹ 2,00,000</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  Additionally, up to three proposals will be awarded ₹ 1,00,000
                  each as decided by the evaluation committee.
                </p>
                <p>
                  All participants will receive a certificate of participation.
                </p>
              </div>

              <div className="bg-white p-2 rounded  mb-1 ">
                <h4 className="col1 " style={{ textTransform: "uppercase" }}>
                  {" "}
                  TERMS AND CONDITIONS
                </h4>
                <ol style={{ textAlign: "justify" }}>
                  <li>
                    The design must be original. Plagiarism will not be allowed
                    and design should not be in violation of copyright Acts in
                    the country of origin or the Indian Copyright Act, 1957 or
                    the Intellectual Property Rights of any third party.
                  </li>
                  <li>
                    By agreeing to submit, an applicant hereby warrants that he
                    or she is the original designer/creator of the design.
                    Further, that the design submitted by the applicant or any
                    constituent part of it is not the intellectual property of
                    any third party. The applicant also understands that in case
                    the submitted design is found to be the intellectual
                    property of any third party, his/her application will stand
                    rejected, and the Nashik Municipal Corporation (NMC) will
                    not be responsible for any infringement whatsoever. Also,
                    NMC will not indemnify any claim by a third party in
                    connection with infringement of Intellectual Rights related
                    to design submitted by an applicant.
                  </li>
                  <li>
                    In case of any copyright issues, the applicant will be
                    responsible to settle any legal proceedings arising out of
                    it at his/her end.
                  </li>
                  <li>
                    All entries are governed by the provisions of Emblems and
                    Names (Prevention of improper use) Act, 1950 and any
                    violation of the said Act will result in disqualification.
                  </li>
                  <li>
                    The design must not contain any provocative, objectionable,
                    or inappropriate content.
                  </li>
                  <li>
                    Winning entries shall be required to provide the original
                    open-source file of the design. The winning designs would be
                    the intellectual property of the NMC, and the winner shall
                    not exercise any right over it.
                  </li>
                  <li>
                    NMC will have unfettered right to modify any design / add /
                    delete any info / design feature in any form to it with due
                    credit given to the original designer.
                  </li>
                  <li>
                    The winning design is meant to be used by NMC for
                    promotional and display purposes, information, education,
                    and communication materials and for any other use as may be
                    deemed appropriate.
                  </li>
                  <li>
                    There will be no notification to applicants of rejected
                    entries.
                  </li>
                  <li>
                    NMC reserves the right to cancel or amend all or any part of
                    this competition and / or terms and conditions / technical
                    parameters / evaluation criteria. However, any changes to
                    the terms and conditions / technical parameters / evaluation
                    criteria, or the cancellation of the competition, will be
                    updated / posted on the platform through which initial
                    communication of competition is made. It would be the
                    responsibility of the participant to keep themselves
                    informed of any changes in the terms and conditions
                    /technical / parameters / evaluation criteria stated for
                    this competition
                  </li>
                  <li>
                    The responsibility to comply with the submission of entries,
                    technical criteria and selection process fully lies with the
                    participants and NMC shall not be answerable to any dispute
                    raised by a third party.
                  </li>
                  <li>
                    The winner will be declared through email. NMC may announce
                    the winner’s name on its social media pages and may also
                    upload details on the official website of the corporation.
                  </li>
                  <li>
                    NMC reserves rights to reject any proposal. A proposal will
                    be excluded from participation if :
                  </li>
                  <li>
                    Proposals can be submitted individually or in a team
                    comprising a maximum of 5 members.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default About;
