import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./focus.css";

const FocusPage = () => {
  const places = [
    {
      img: "/a (2).jpeg",
      name: "Kushavart Tirth",
      location: "Trimbakeshwar",
    },
    {
      img: "a (4).jpeg",
      name: "Ahilya Goda Sangam",
      location: "Trimbakeshwar",
    },
    {
      img: "a (3).jpeg",
      name: "Shri Chandra Ghat",
      location: "Trimbakeshwar",
    },
    {
      img: "a (6).jpeg",
      name: "Someshwar Waterfall & Temple",
      location: "Nashik",
    },
    {
      img: "a (1).jpeg",
      name: "Holkar Bridge to Talkuteshwar Temple",
      location: "Nashik",
    },
    { img: "a (5).jpeg", name: "Tapovan", location: "Nashik" },
  ];

  return (
    <div className="container text-center py-2">
      <h2 className="secondary-title" style={{ textTransform: "uppercase" }}>
        Focus Areas
      </h2>
      <div className="row mt-5">
        {places.map((item) => {
          return (
            <>
              <div className="col-md-4 col-sm-6 col-lg-4 mb-4">
                <div className="card text-center card-hover h-100 shadow-lg border-0">
                  <img
                    src={item.img}
                    className="card-img-top"
                    alt="Panchavati Ghat"
                  />
                  <div className="card-body">
                    <h5 className="card-title">{item.name}</h5>
                    {/* <p className="card-text">
                      An important heritage site along the Godavari river.
                    </p> */}
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default FocusPage;
